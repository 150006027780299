var render, staticRenderFns
import script from "./index.vue?vue&type=script&lang=jsx&"
export * from "./index.vue?vue&type=script&lang=jsx&"
import style0 from "./index.vue?vue&type=style&index=0&id=616d3ee6&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "616d3ee6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/jenkins/jobs/management-system-frontend/workspace/ant-design-vue-jeecg/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('616d3ee6')) {
      api.createRecord('616d3ee6', component.options)
    } else {
      api.reload('616d3ee6', component.options)
    }
    
  }
}
component.options.__file = "src/components/TagDragSelect/index.vue"
export default component.exports