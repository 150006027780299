<!-- https://gitee.com/ovsexia/DragSelect-Doc-Cn -->
<!-- https://github.com/houbb/opencc4j -->
<!-- http://dragselect.com/DragSelect.html#addSelectables -->
<script lang="jsx">
import { decodeWord, encodeWord } from '@/utils/util.js'
import DragSelect from 'dragselect'
export default {
  name: 'TagDragSelect',
  props: {
    // 组件外带过来的数据
    propDataList: {
      type: Array,
      default: []
    },
    isStage: {
      type: Boolean,
      default: false
    },
    // 全局id
    scopeId: {
      type: [Number, String],
      required: true
    },
    // 是否作品详情调用
    isPm: {
      type: Boolean,
      default: false
    },
    classId: {
      type: [Number, String],
      default: 3
    }
  },
  model: {
    prop: 'propDataList',
    event: 'change'
  },
  data() {
    return {
      ds: null,
      selection: [],
      dataList: [],
      editIdx: -1
    }
  },
  created() {
    /* this.dataList = Array.from({ length: 1000 }).map((item, index) => {
          return { idx: index, id: index, value: `词语${index}` };
        }); */
    this.dataList = this.propDataList.map(({ id, values, value, target, remark }, index) => {
      return { id: id || index, values, value, target, remark }
    })
    console.log({ 'this.dataList2': this.dataList })
  },
  mounted() {
    console.log(window)
    console.log(document)
    console.log({ '`tag-drag-group-${this.scopeId}`': `tag-drag-group-${this.scopeId}` })
    console.log({ '`mar-${this.scopeId}`': `mar-${this.scopeId}` })
    this.ds = new DragSelect({
      area: document.getElementById(`tag-drag-group-${this.scopeId}`),
      selectables: document.getElementsByClassName(`mar-${this.scopeId}`),
      draggability: false,
      multiSelectMode: true,
      //鼠标抬起后返回所有选中的元素
      callback: elements => {
        console.log(elements)
      }
    })
    console.log({ 'this.ds': this.ds })
  },
  methods: {
    selectAll() {
      // 全选
      this.ds.setSelection(this.ds.getSelectables())
    },
    selectNone() {
      // 全不选
      this.ds.clearSelection()
    },
    saveTag(index, allValue) {
      console.log(allValue,123)
      allValue.split('#"').forEach((value, aidx) => {
        if (value && aidx < allValue.split('#"').length - 1) {
          value = value + '"'
          // 保存
          /* console.log(value.matchAll(/"/g))
            console.log({txt}) */
          value = value.replace(/\n(?=[^"]*"[^"]*$)/g, '<br>')
          value = value.replace(/"/g, '')
          console.log('saveTag', { value })
          console.log('saveTag', { 'this.dataList': this.dataList })
          if (
            value &&
            value
              ?.replaceAll('\t', '')
              ?.replaceAll('\n')
              ?.trim()
          ) {
            // 如果是编辑
            const list = value.split('\n').map((value, idx) => {
              let target, remark
              value = value.replace(/<br>/g, '\n')
              console.log(value)
              value = encodeWord(value?.trim())
                .replaceAll('>', '&gt;')
                .replaceAll('<', '&lt;')
              // 判断是否有转换目标分隔符
              console.log('saveTag afterTrim', { value, 'this.classId': this.classId })
              const splitor = ['\t', '(', '→', '='].find(o => value && value.includes(o))
              if (this.classId != 12) {
                if (splitor) {
                  ;[value, target, remark] = value.split(splitor)
                }
                value = value?.trim() || null
                target = target?.replace(')', '').trim() || null
                remark = remark?.trim() || null
                return { value, target, remark, id: this.genId(idx) }
              }
              let values = value.split(splitor)
              if (values.findIndex(item => item.indexOf('*') > -1) > -1) {
                remark = values[values.findIndex(item => item.indexOf('*') > -1)].trim()
                values.splice(
                  values.findIndex(item => item.indexOf('*') > -1),
                  1
                )
              }
              values = [...new Set(values)].map(item => item.trim())
              if (remark) remark = remark.replaceAll('*', '')
              return { values, remark, id: this.genId(idx) }
            })
            console.log({ list })
            this.dataList.splice(index+aidx-0+1, 1, ...list)
            console.log({ 'this.dataList': this.dataList })
            this.resetSelectable()
          } else {
            // 如果没有值
            this.dataList.splice(index+aidx-0+1, 1)
          }
          // 将当前编辑框隐藏
          this.editIdx = -1
        } else if (value && allValue.split('#"').length == 1) {
          // 保存
          /* console.log(value.matchAll(/"/g))
            console.log({txt}) */
          value = value.replace(/\n(?=[^"]*"[^"]*$)/g, '<br>')
          value = value.replace(/"/g, '')
          console.log('saveTag', { value })
          console.log('saveTag', { 'this.dataList': this.dataList })
          if (
            value &&
            value
              ?.replaceAll('\t', '')
              ?.replaceAll('\n')
              ?.trim()
          ) {
            // 如果是编辑
            const list = value.split('\n').map((value, idx) => {
              let target, remark
              value = value.replace(/<br>/g, '\n')
              console.log(value)
              value = encodeWord(value?.trim())
                .replaceAll('>', '&gt;')
                .replaceAll('<', '&lt;')
              // 判断是否有转换目标分隔符
              console.log('saveTag afterTrim', { value, 'this.classId': this.classId })
              const splitor = ['\t', '(', '→', '='].find(o => value && value.includes(o))
              if (this.classId != 12) {
                if (splitor) {
                  ;[value, target, remark] = value.split(splitor)
                }
                value = value?.trim() || null
                target = target?.replace(')', '').trim() || null
                remark = remark?.trim() || null
                return { value, target, remark, id: this.genId(idx) }
              }
              let values = value.split(splitor)
              if (values.findIndex(item => item.indexOf('*') > -1) > -1) {
                remark = values[values.findIndex(item => item.indexOf('*') > -1)].trim()
                values.splice(
                  values.findIndex(item => item.indexOf('*') > -1),
                  1
                )
              }
              values = [...new Set(values)].map(item => item.trim())
              if (remark) remark = remark.replaceAll('*', '')
              return { values, remark, id: this.genId(idx) }
            })
            console.log({ list })
            this.dataList.splice(index, 1, ...list)
            console.log({ 'this.dataList': this.dataList })
            this.resetSelectable()
          } else {
            // 如果没有值
            this.dataList.splice(index, 1)
          }
          // 将当前编辑框隐藏
          this.editIdx = -1
        }
      })
    },
    genId(idx) {
      return this.dataList.length + idx + parseInt(Math.random() * 100) + new Date().getTime()
    },
    syncPropDataList() {
      // 同步到父组件传过来的数据
      console.log('syncPropDataList', { 'this.dataList': this.dataList })
      this.$emit('change', this.dataList)
    },
    selectReverse(elements = this.ds.getSelectables()) {
      // 反选
      const willRemoveSelection = []
      const willAddSelection = []
      elements.forEach(el => (this.ds.SelectedSet.has(el) ? willRemoveSelection : willAddSelection).push(el))
      this.ds.removeSelection(willRemoveSelection)
      this.ds.addSelection(willAddSelection)
    },
    resetSelectable() {
      console.log('resetSelectable', { 'this.dataList1': this.dataList })
      if (this.classId != 12) {
        // 对dataList进行去空、去重
        this.dataList = this.dataList
          .filter(({ value }) => value && value?.trim())
          .reduce((acc, { value, target, remark, id }) => {
            if (acc.find(({ value: val }) => val === value)) {
              return acc
            }
            acc.push({ value, target, remark, id })
            return acc
          }, [])
      } else {
        // 对values进行去空
        this.dataList = this.dataList
          .map(item => {
            item.values = item.values?.filter(value => value && value?.trim()) || []
            return item
          })
          .filter(item => item.values?.length)
      }
      // 重置selectable
      this.$nextTick(() => this.ds.addSelectables(document.getElementsByClassName(`mar-${this.scopeId}`)))
      // 重置selection
      this.ds.setSelection([])
      this.syncPropDataList()
    },
    copyText() {
      const text = this.ds
        .getSelection()
        .filter(({ innerText }) => innerText)
        .map(({ innerText }) => innerText)
        .join('\r\n')
      if (navigator.clipboard) return navigator.clipboard.writeText(text)
      const textareaEle = document.createElement('textarea')
      document.body.appendChild(textareaEle)
      // 2. 将需要复制的文本传入输入框, 并调用 select 方法, 选中输入框中文本
      textareaEle.value = text
      textareaEle.select()
      textareaEle.readOnly = 'readOnly'
      // 3. 调用复制选中文本的方法
      document.execCommand('copy')
      // 4. 销毁输入框
      document.body.removeChild(textareaEle)
    },
    addTag() {
      // 新增，添加到最后一个
      this.editIdx = this.dataList.length
      this.dataList.push({ id: this.genId(this.editIdx), value: '', target: null, values: [], remark: null })
      this.$nextTick(() => this.$refs.input?.focus())
    },
    delSelectedTags() {
      // 删除选中
      const selectionIds = this.getSelectionIds()
      console.log({ selectionIds })
      this.dataList = this.dataList.filter(item => !~selectionIds.findIndex(id => id == item.id))
      this.resetSelectable()
    },
    delTag(id) {
      // 删除一个
      const idx = this.dataList.findIndex(i => i.id === id)
      console.log('delTag', { 'this.dataList': this.dataList, id, idx })
      this.dataList.splice(idx, 1)
      console.log('delTag after delete', { 'this.dataList': this.dataList })
      this.resetSelectable()
    },
    getSelectionIds() {
      // 获取选中ids
      return this.ds.getSelection().map(({ id }) => Number(id.replace(`tag-drag-`, '')))
    },
    tabInput(e) {
      const insertText = '\t'
      const elInput = e.target
      const startPos = elInput.selectionStart
      const endPos = elInput.selectionEnd
      if (startPos === undefined || endPos === undefined) return
      const txt = elInput.value
      elInput.value = txt.substring(0, startPos) + insertText + txt.substring(endPos)
      elInput.focus()
      elInput.selectionStart = startPos + insertText.length
      elInput.selectionEnd = startPos + insertText.length
      this.inputValue = elInput.value
    }
  },
  render() {
    console.log('render', this.dataList)
    // 判断是否渲染编辑框还是标签
    const tagItemRender = ({ id, values, value, target, remark }, index) => {
      console.log({ 'this.classId': this.classId, values })
      let itemDom = null
      let text
      if (this.classId != 12) {
        text = decodeWord(target ? value + ' → ' + target : value)
      } else {
        text = values?.map(i => decodeWord(i)).join(' → ') || ''
      }
      text = decodeWord(remark ? text + ' → ' + remark : text)
      if (this.editIdx == index) {
        /* let inputText = decodeWord(target ? value + '\t' + target : value) */
        let inputText = text
        itemDom = (
          <a-textarea
            compact
            size="small"
            vModel={inputText}
            ref="input"
            vOn:blur_native={() => this.saveTag(index, inputText)}
            vOn:keydown_tab_native_prevent_stop={this.tabInput}
          />
        )
      } else {
        itemDom = (
          <a-tag
            closable
            color="blue"
            vOn:close_stop_prevent={() => this.delTag(id)}
            vOn:dblclick_stop_prevent={() => {
              this.editIdx = index
              this.$nextTick(() => this.$refs.input?.focus())
            }}
          >
            {text}
          </a-tag>
        )
        if (text.length > 20) {
          // 如果value长度太长，则显示tooltip
          itemDom = <a-tooltip title={text}>{itemDom}</a-tooltip>
        }
      }
      return itemDom
    }
    if (this.isPm) {
      return (
        <div class="tag-drag-select" vOn:click_stop_prevent={() => this.ds.start()}>
          <div id={`tag-drag-group-${this.scopeId}`} class="ul">
            {this.dataList.map((item, index) => (
              <div class="li" key={item.id}>
                <div class={`mar-${this.scopeId}`} id={`tag-drag-${item.id}`}>
                  {tagItemRender(item, index)}
                </div>
              </div>
            ))}
          </div>
          {/* 按钮组 */}
          <div class="button-group">
            <a-button icon="check" size="small" onClick={this.selectAll}>
              全选
            </a-button>
            <a-button icon="close" type="dashed" size="small" onClick={this.selectNone}>
              全不选
            </a-button>
            <a-button icon="delete" size="small" onClick={this.delSelectedTags}>
              删除
            </a-button>
            <a-button icon="copy" size="small" onClick={this.copyText}>
              复制
            </a-button>
            <a-button icon="plus" size="small" onClick={this.addTag}>
              新增
            </a-button>
          </div>
        </div>
      )
    } else {
      return (
        <div class="tag-drag-select" vOn:click_stop_prevent={() => this.ds.start()}>
          <div id={`tag-drag-group-${this.scopeId}`} class="ul">
            {this.dataList.map((item, index) => (
              <div class={this.editIdx == index ? 'li li-input' : 'li'} key={item.id}>
                <div class={`mar-${this.scopeId}`} id={`tag-drag-${item.id}`}>
                  {tagItemRender(item, index)}
                </div>
              </div>
            ))}
          </div>
          {/* 按钮组 */}
          <div class="button-group">
            <a-button icon="check" size="small" onClick={this.selectAll}>
              全选
            </a-button>
            <a-button icon="close" type="dashed" size="small" onClick={this.selectNone}>
              全不选
            </a-button>
            <a-button icon="border-outer" size="small" onClick={() => this.selectReverse()}>
              反选
            </a-button>
            {this.isStage ? (
              ''
            ) : (
              <a-button icon="delete" size="small" onClick={this.delSelectedTags}>
                删除选中
              </a-button>
            )}
            {this.isStage ? (
              ''
            ) : (
              <a-button icon="copy" size="small" onClick={this.copyText}>
                复制选中
              </a-button>
            )}
            {this.isStage ? (
              ''
            ) : (
              <a-button icon="plus" size="small" onClick={this.addTag}>
                新增
              </a-button>
            )}
          </div>
        </div>
      )
    }
  }
}
</script>

<style scoped lang="less">
.tag-drag-select {
  user-select: none;
}

.tag-drag-select {
  > div {
    > .li-input {
      width: 100% !important;

      .ant-input {
        height: 160px !important;
      }
    }
  }
}

.ul {
  padding: 20px;
  user-select: none;
  overflow: auto;
  height: 200px;
  border: 1px solid #ccc;
}

.li {
  float: left;
  margin: 10px 0px;
}

/deep/ .ds-selected > .ant-tag {
  background: #108ee9;
  color: #fff;
}

.button-group {
  margin-top: 10px;
}

.button-group .ant-btn {
  margin-right: 10px;
}
</style>
